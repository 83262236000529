.sidebar {
    direction: ltr;
    /* position: fixed; */
    max-width: 230px;
    min-width: 230px;
    /* z-index: 999; */
    /* overflow-y: auto; */
    top: 0;
    background: #222e3c;
    /* z-index: 999;
    transition: transform 0.3s ease-in-out, max-width 0.3s ease-in-out, min-width 0.3s ease-in-out;
    left: -260px; */
    /* add this line to move the sidebar outside of the screen */
    /* transform: translateX(0); */
    /* add this line to set the initial position of the sidebar */
}

/* .sidebar,
.sidebar_content {
    background: #222e3c;
    transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out
} */


.li {
    padding-left: 5px;
    font-size: small;
}

.sidebar_content {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

/* .sidebar.open .sidebar_content {
    transform: translateX(-260px);
} */
/* 
#sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    background-color: #fff;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
} */

.sidebar_nav {
    flex-grow: 1;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.copywrite {
    color: #fff !important;
    padding: .7rem;
}

.a_tags {
    color: #fff !important;
    font-weight: bold;
    text-decoration: none;
    font-size: 1rem;
    /* font-size: 18px; */
}
.a_tagsp {
    color: #fff !important;
    /* font-weight: bold; */
    text-decoration: none;
    font-size: 0.9rem;
    /* font-size: 18px; */
}

.sidebar_link,
a.sidebar_link {
    background: #222e3c;
    border-left: 3px solid transparent;
    color: rgba(233, 236, 239, .5);
    cursor: pointer;
    display: block;
    font-weight: 400;
    padding: .625rem 1.625rem;
    position: relative;
    text-decoration: none;
    transition: background .1s ease-in-out
}

.sidebar_link i,
.sidebar_link svg,
a.sidebar_link i,
a.sidebar_link svg {
    color: rgba(233, 236, 239, .5);
}

.sidebar_link:focus {
    outline: 0
}

.sidebar_link:hover {
    background: #d1d7dd;
    color: #fff;
    border-left-color: transparent
}

.sidebar_link:hover,
.sidebar_link:hover i,
.sidebar_link:hover svg {
    color: rgba(233, 236, 239, .75)
}

.sidebar_item.active .sidebar_link:hover,
.sidebar_item.active>.sidebar_link {
    background: linear-gradient(90deg, rgba(59, 125, 221, .1), rgba(59, 125, 221, .088) 50%, transparent);
    border-left-color: #3b7ddd;
    color: #e9ecef
}

.sidebar_item.active .sidebar_link:hover i,
.sidebar_item.active .sidebar_link:hover svg,
.sidebar_item.active>.sidebar_link i,
.sidebar_item.active>.sidebar_link svg {
    color: #e9ecef
}

.sidebar_brand {
    color: #f8f9fa; 
    display: block;
    font-size: 1.15rem;
    font-weight: 600;
    padding: 1.15rem 1.5rem
}

.sidebar_brand:hover {
    color: #f8f9fa ;
    text-decoration: none
}

.sidebar_brand:focus {
    outline: 0
}

.sidebar_toggle {
    cursor: pointer;
    display: flex;
    height: 26px;
    width: 26px
}

.sidebar.collapsed {
    margin-left: -260px
}

.sidebar-toggle {
    margin-right: 1rem
}

.sidebar_header {
    background: transparent;
    color: #ced4da;
    font-size: 1rem;
    font-weight: bold;
    padding: 1.5rem 1.5rem .375rem
}


@media screen and (max-width: 1050px) and (max-height: 640px) {
    .sidebar {
        direction: ltr;
        max-width: 200px;
        min-width: 200px;
        top: 0;
        background: #222e3c;
    }

    .sidebar_header {
        padding: 1.0rem 1.5rem .475rem        
    }

    .a_tags {
        color: #fff !important;
        font-size: 0.95rem;
    }
}

@media screen and (max-width: 800px) {
    .sidebar {
        position: fixed;
        z-index: 999;
    }
    
}