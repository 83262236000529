h1 {
    color: #000;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: .5rem;
    margin-top: 0;
    font-size: 1.75rem
}


.card_title {
    margin-bottom: 0.5rem;
    color: #939ba2;
    font-size: .925rem;
    font-weight: 600;
}

.stat {
    align-items: center;
    background: #d3e2f7;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px
}

@media (max-width: 520px) {
    .stat {
        display: none;
    }

    .h1 {
        font-size: 1.2rem;
    }
}